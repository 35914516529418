import React, { useEffect } from 'react';

import * as Styles from './styled/RKPage.styled';

import AsideMenu from '../components/common/AsideMenu';
import Header from '../components/_RK/Header';
import Modals from '../components/Modals';

import { useDesktop } from '../styled/useMedia';

import { navigations, socials } from '../data/rk';
import { logo } from '../data/common';
import { sections } from '../data/rk/sections';
import storeApp from '../store/AppStore';
import { EAuthModalSteps, EModalNames } from '../components/Modals/Modals.type';

const RKPage = () => {
  const isDesktop = useDesktop();

  useEffect(() => {
    const url = new URL(window.location.href);
    const _openParams = url.searchParams.get('open');
    if (_openParams === 'login') {
      storeApp.modalOpen(EModalNames.Enter);
      storeApp.setAuthModalData(EAuthModalSteps.Enter);
    } else if (_openParams === 'signup') {
      storeApp.modalOpen(EModalNames.Enter);
      storeApp.setAuthModalData(EAuthModalSteps.Register);
    }
  }, []);

  return (
    <Styles.Root>
      {isDesktop && (
        <AsideMenu navigations={navigations} logo={logo} socials={socials} />
      )}
      <Header navigations={navigations} socials={socials} logo={logo} />
      <Styles.Main>
        {sections.map((section) => {
          const Section = section.component;

          return (
            <Styles.Item id={section.name} key={`rk-${section.name}`}>
              <Section {...section.content} />
            </Styles.Item>
          );
        })}
      </Styles.Main>
      <svg
        width='71'
        height='71'
        viewBox='0 0 71 71'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='faq-widget'
        onClick={() =>
          window.open(
            'https://sites.google.com/view/faqbidfox/%D0%BE-%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D0%B5',
            '_blank',
            'noopener,noreferrer'
          )
        }
      >
        <g clip-path='url(#clip0_22274_531010)'>
          <circle opacity='0.3' cx='35.5' cy='35.5' r='35.5' fill='#40A1FF' />
          <circle cx='35.5' cy='35.5' r='29.5' fill='#40A1FF' />
          <path
            d='M14.049 45V27.84H25.697V31.87H18.521V35.146H24.345V39.176H18.521V45H14.049ZM36.9956 45L36.3716 43.57H30.2096L29.5856 45H24.7756L32.5756 27.528H34.0056L41.8056 45H36.9956ZM34.7856 39.982L33.3036 36.004L31.7956 39.982H34.7856ZM69.2234 47.158V50.668C68.2874 50.9107 67.2128 51.032 65.9994 51.032C63.5554 51.032 61.3021 50.5207 59.2394 49.498C57.1941 48.4753 55.4781 46.9673 54.0914 44.974C53.3461 45.1993 52.4968 45.312 51.5434 45.312C49.0128 45.3467 46.8288 44.4887 44.9914 42.738C43.1541 40.9873 42.2614 38.8813 42.3134 36.42C42.2614 33.976 43.1541 31.87 44.9914 30.102C46.8461 28.334 49.0301 27.476 51.5434 27.528C54.0568 27.476 56.2408 28.334 58.0954 30.102C59.9674 31.87 60.8774 33.976 60.8254 36.42C60.8254 39.072 59.8201 41.282 57.8094 43.05C58.9534 44.506 60.2448 45.5633 61.6834 46.222C63.1221 46.898 64.8641 47.236 66.9094 47.236C67.4988 47.236 68.2701 47.21 69.2234 47.158ZM51.5694 41.204C52.9214 41.204 54.0394 40.762 54.9234 39.878C55.8074 38.994 56.2494 37.8413 56.2494 36.42C56.2494 35.016 55.7988 33.872 54.8974 32.988C54.0134 32.0867 52.9041 31.636 51.5694 31.636C50.1828 31.636 49.0561 32.078 48.1894 32.962C47.3228 33.846 46.8894 34.9987 46.8894 36.42C46.8894 37.8413 47.3228 39.0027 48.1894 39.904C49.0561 40.7707 50.1828 41.204 51.5694 41.204Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_22274_531010'>
            <rect width='71' height='71' rx='35.5' fill='white' />
          </clipPath>
        </defs>
      </svg>

      <Modals />
    </Styles.Root>
  );
};

export default RKPage;
